<template>
  <div class="mine_box">
    <div class="mine_left">
      <mineMenu v-if="isRouterAlive"></mineMenu>
    </div>
    <div class="mine_right">
      <router-view />
    </div>
  </div>
</template>
<script>
import mineMenu from "./components/mine_menu";
export default {
  name: "mine",
  components: {
    mineMenu,
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    
  },
  watch:{
   
  },
  mounted(){
     
  },
  beforeDestroy(){
    sessionStorage.removeItem("mine_menu")
  },
  methods: {
    // reload() {
    //   this.isRouterAlive = false; //先关闭，
    //   this.$nextTick(function () {
    //     this.isRouterAlive = true; //再打开
    //   });
    // },
    
  },
  // provide() {
  //   //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
  //   return {
  //     reload: this.reload,
  //   };
  // },
};
</script>
<style lang="scss" scoped>
.mine_box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 15px
}
.mine_left {
  margin-top: 20px;
}
.mine_right {
  margin-top: 20px;
  width: calc(100% - 250px);
  // box-shadow: 0 0 5px #e5e5e5;
  > div{
    min-height: 570px;
    /deep/ {
      .el-tabs__content {
        min-height: calc(570px - 40px);
      }
    }
  }
}
</style>


