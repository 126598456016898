var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mineMenu"},[_c('div',{staticClass:"info_top"},[(_vm.info && _vm.info.avatar)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.info.avatar,"alt":"","onerror":_vm.errorImg01}}):_vm._e(),_c('p',{staticClass:"nick_name",attrs:{"ellipsis":""}},[_vm._v(_vm._s(_vm.info.nickname))]),_c('span',{staticStyle:{"color":"#999999"}},[_vm._v("ID："+_vm._s(_vm.info.user_unix_id))]),_c('p',{staticClass:"coin"},[_c('span',[_vm._v("鲸币：")]),_vm._v(_vm._s(_vm.info.book_coin))]),_c('div',{staticClass:"sex"},[_c('span',{staticClass:"sex_sex"},[_c('i',{staticClass:"iconfont",class:_vm.info.sex === 0
              ? 'icon-xingbie nobady_col'
              : _vm.info.sex === 1
              ? 'icon-nan1 man_col'
              : 'icon-female women_col'}),_vm._v(" "+_vm._s(_vm.info.sex === 0 ? "保密" : _vm.info.sex === 1 ? "男" : "女")+" ")]),_c('span',{staticClass:"sex_lv",class:_vm.info.vip ? 'is_lv' : '',domProps:{"textContent":_vm._s(_vm.info.is_vip ? _vm.info.vip : '暂无')},on:{"click":function($event){return _vm.go_charge()}}}),_c('span',{staticClass:"is_vip",class:_vm.info.is_vip === 1 ? 'light_col' : '',on:{"click":function($event){return _vm.go_charge()}}},[_vm._v("VIP")])]),(_vm.info.vip_end_time)?_c('div',{staticClass:"out-date"},[_vm._v(" 会员有效期至："+_vm._s(_vm.formatTime(new Date(_vm.info.vip_end_time * 1000), "YYYY-MM-DD hh:mm"))+" ")]):_vm._e(),(!_vm.author)?_c('el-button',{staticClass:"be_author",attrs:{"type":"primary","plain":"","size":"small"},on:{"click":function($event){return _vm.$router.push({ path: '/go_register' })}}},[_vm._v(" 我要当作家 ")]):_vm._e()],1),_c('div',{staticClass:"mine_menu_box"},_vm._l((_vm.menu_list),function(item,index){return _c('div',{key:index,staticClass:"mine_menu_item",class:_vm.menu_id === item.id ? 'choose_it' : '',on:{"click":function($event){return _vm.go_page(item)}}},[_c('i',{staticClass:"iconfont",class:item.icon}),_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"menu_btm"},[_c('div',{staticClass:"author_menu"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        (_vm.author_menu.name === '作家中心' && _vm.author ? true : false) ||
        _vm.author_menu.name != '作家中心'
      ),expression:"\n        (author_menu.name === '作家中心' && author ? true : false) ||\n        author_menu.name != '作家中心'\n      "}],staticClass:"mine_menu_item",class:_vm.menu_id === _vm.author_menu.id ? 'choose_it' : '',on:{"click":function($event){return _vm.go_page(_vm.author_menu)}}},[_c('i',{staticClass:"iconfont",class:_vm.author_menu.icon}),_vm._v(" "+_vm._s(_vm.author_menu.name)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }