<template>
    <div>
        <div class="mineMenu">
            <div class="info_top">
                <img
                    v-if="info && info.avatar"
                    :src="info.avatar"
                    alt=""
                    class="avatar"
                    :onerror="errorImg01"
                />

                <p class="nick_name" ellipsis>{{ info.nickname }}</p>
                <span style="color: #999999">ID：{{ info.user_unix_id }}</span>
                <p class="coin"><span>鲸币：</span>{{ info.book_coin }}</p>
                <div class="sex">
          <span class="sex_sex">
            <i
                class="iconfont"
                :class="
                info.sex === 0
                  ? 'icon-xingbie nobady_col'
                  : info.sex === 1
                  ? 'icon-nan1 man_col'
                  : 'icon-female women_col'
              "
            ></i>
            {{ info.sex === 0 ? "保密" : info.sex === 1 ? "男" : "女" }}
          </span>
                    <span
                        class="sex_lv"
                        :class="info.vip ? 'is_lv' : ''"
                        v-text="info.is_vip ? info.vip : '暂无'"
                        @click="go_charge()"
                    ></span>

                    <span
                        class="is_vip"
                        :class="info.is_vip === 1 ? 'light_col' : ''"
                        @click="go_charge()"
                    >VIP</span
                    >

                    <!-- <i class="iconfont icon-vip2 is_vip" :class="info.is_vip?'light_col':''" @click="go_charge()"></i> -->
                </div>
                <div class="out-date" v-if="info.vip_end_time">
                    会员有效期至：{{
                        formatTime(new Date(info.vip_end_time * 1000), "YYYY-MM-DD hh:mm")
                    }}
                </div>

                <el-button
                    class="be_author"
                    v-if="!author"
                    @click="$router.push({ path: '/go_register' })"
                    type="primary"
                    plain
                    size="small"
                >
                    我要当作家
                </el-button>
            </div>
            <div class="mine_menu_box">
                <div
                    v-for="(item, index) in menu_list"
                    :key="index"
                    class="mine_menu_item"
                    :class="menu_id === item.id ? 'choose_it' : ''"
                    @click="go_page(item)"
                >
                    <i class="iconfont" :class="item.icon"> </i> {{ item.name }}
                </div>
            </div>
        </div>
        <div class="menu_btm">
            <div class="author_menu">
                <div
                    v-show="
            (author_menu.name === '作家中心' && author ? true : false) ||
            author_menu.name != '作家中心'
          "
                    class="mine_menu_item"
                    :class="menu_id === author_menu.id ? 'choose_it' : ''"
                    @click="go_page(author_menu)"
                >
                    <i class="iconfont" :class="author_menu.icon"> </i>
                    {{ author_menu.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {PersonInfo, Moneyinfo} from "@/https/mine_center.js";
import {mapGetters} from "vuex";

export default {
    name: "mineMenu",
    props: {},
    data() {
        return {
            menu_list: [
                {link: "/mine_center", name: "个人中心", id: 1, icon: "icon-ren"},
                {
                    link: "/mine_shelf",
                    name: "我的书架",
                    id: 2,
                    icon: "icon-wodeshujia",
                },
                {
                    link: "/friendly",
                    name: this.open_relation() ? "师徒&好友" : "我的好友",
                    id: 4,
                    icon: "icon-laoshi1",
                },
                {
                    link: "/mine_common",
                    name: "我的评论",
                    id: 5,
                    icon: "icon-wodepinglun",
                },
                {
                    link: "/finance_center",
                    name: "财务中心",
                    id: 6,
                    icon: "icon-tubiao_caiwutongji",
                },
                {
                    link: "/recharge",
                    name: "充值中心",
                    id: 7,
                    icon: "icon-chongzhi1",
                },
                {
                    link: "/ticket",
                    name: "推荐票明细",
                    id: 8,
                    icon: "icon-piao",
                },
                {
                    link: "/owner_set",
                    name: "个人设置",
                    id: 9,
                    icon: "icon-gerenshezhi",
                },
                localStorage.getItem('api_token') ?
                    {
                        link: `${window.serverUrl}api/common/jumpWordPress?token=${localStorage.getItem('api_token') || ''}`,
                        name: "鲸云学院",
                        id: 10,
                        type: 'page',
                        icon: "icon-shu1",
                    } : null,
            ],
            errorImg01:
                'this.src="' +
                require("../../../../public/static/default_header.png") +
                '"',
            author_menu: {
                link: "/author_center",
                name: "作家中心",
                id: 3,
                icon: "icon-zuojiahoutai-xiugai",
            },
            menu_id: 1,
            info: {
                nickname: "",
                id: "",
                vip: "",
            },
            person_info: JSON.parse(sessionStorage.getItem("person_info")),
            author: false,
            channel_arr: [],
        };
    },
    watch: {
        aviod_mine_menu(n, v) {
            this.aviod_menu_storage();
        },
    },
    computed: {
        ...mapGetters(["aviod_mine_menu"]),
    },

    created() {
        this.aviod_info();
        this.aviod_menu_storage();
        // this.person_info = JSON.parse(sessionStorage.getItem('person_info'));
    },
    mounted() {
        // this.aviod_info();
    },
    methods: {
        // 目录界面跳转
        go_page(row) {
            sessionStorage.setItem("mine_menu", JSON.stringify(row));
            this.menu_id = row.id;
            if(row.type === 'page'){
                // window.open(row.link);
                let aEle = document.createElement('a');
                aEle.href = row.link;
                aEle.target = '_blank';
                aEle.click();
                aEle = null;
            }else if (row.link) {
                this.$router.push({
                    path: `${row.link}`,
                });
            }
        },
        // 获取左侧用户信息
        aviod_info() {
            Moneyinfo().then((res) => {
                if (res.code === 0) {
                    this.author = res.data.is_author === 1 ? true : false;
                    this.info = res.data;
                    sessionStorage.setItem("person_id", this.info.user_unix_id);
                    // sessionStorage.setItem('is_author',res.data.is_author);
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
        // 获取菜单缓存信息
        aviod_menu_storage() {
            let session = sessionStorage.getItem("mine_menu");
            if (session) {
                this.menu_id = Number(JSON.parse(session).id);
                this.$router.push({
                    path: `${JSON.parse(session).link}`,
                });
            } else {
                this.menu_list.forEach((item, index) => {
                    if (this.$route.path === item.link) {
                        this.menu_id = item.id;
                        sessionStorage.setItem("mine_menu", JSON.stringify(item));
                    }
                });
            }
        },
        // 充值中心
        go_charge() {
            this.menu_id = 7;
            sessionStorage.setItem("mine_menu", JSON.stringify(this.menu_list[6]));
            this.$router.push({
                path: "/recharge",
            });
        },
    },
    mounted() {
    },
};
</script>
<style lang="scss" scoped>
* {
    font-size: 14px;
}

.mineMenu {
    background-color: #fff;
    // border: 1px solid #eeeeee;
    // box-shadow: 0 0 5px #e4e4e4;
    border-radius: 8px;
    width: 230px;
    margin-right: 20px;

    .info_top {
        padding: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 26px;
        border-bottom: 1px solid #eeeeee;

        .avatar {
            width: 80px;
            height: 80px;
            margin-bottom: 5px;
        }

        .nick_name {
            font-weight: bold;
            width: 80%;
            text-align: center;
        }

        .coin {
            color: #fc9c0e;

            span {
                color: #000;
            }
        }

        .sex {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            span {
                margin: 0 5px;
                font-size: 12px;
            }
        }

        .be_author {
            margin-top: 15px;
        }
    }
}

.mine_menu_box {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    // border-bottom: 1px solid #eeeeee
}

.choose_it {
    color: #409eff !important;
    border-left: 4px solid #409eff !important;
}

.go_works {
    // padding: 15px 15px 15px 15px;
    // text-align: center;
    padding-left: 20px;
    font-size: 16px;
    line-height: 40px;

    &:hover {
        cursor: pointer;
        color: #409eff;
    }
}

.light_col {
    background: rgb(249, 38, 88) !important;
}

.is_vip {
    cursor: pointer;
    color: #999999;
    font-size: 16px;
    line-height: 18px;
    padding: 0 8px;
    color: #ffffff;
    background: #cacaca;
    border-radius: 2px;
}

.man_col {
    color: #36d6fe;
}

.women_col {
    color: #f75c60;
}

.nobady_col {
    color: #666666;
}

.no_text {
    cursor: pointer;
    background: #cacaca !important;
}

.mine_menu_item {
    padding-left: 20px;
    font-size: 16px;
    line-height: 40px;
    border-left: 4px solid transparent;
    cursor: pointer;

    &:hover {
        color: #409eff;
    }
}

.menu_btm {
    padding: 10px 0;
    background-color: #fff;
    // box-shadow: 0 0 5px #e4e4e4;
    border-radius: 8px;
    width: 230px;
    margin-top: 10px;
    margin-right: 20px;
}

.sex_lv {
    width: 36px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #cacaca;
    border-radius: 9px;
    color: #ffffff;
    cursor: pointer;
}

.out-date {
    font-size: 12px;
    color: #999;
}

.is_lv {
    background: red;
}
</style>
